import TypesHelpers from './types.helpers';
import TypesInternationalization from './types.internationalization';

const Types = {};

Object.assign(
  Types,
  TypesHelpers.createValues('SYSTEM_COUNTRY', [
    ['rs', 'RSD', 'Europe/Belgrade', {
      signup: true,
      checkoutComChannel: 'srb',
      paymentMethods: ['CARD', 'COD_CASH', 'COD_CARD', 'FREE'],
      paymentCardProvider: 'ALLSECURE',
      paymentProviders: ['ALLSECURE'],
      paymentBankAccount: '325-9500700038035-65',
      supportsPayspot: true,
      email: {
        NO_REPLY_EMAIL: 'no-reply@mozhe.rs',
        REPORT_EMAIL: 'support@mozhe.rs',
      },
      warehouse: {
        details: {
          name: 'Mozhe Storage',
          email: 'hello@mozhe.rs',
          phoneNumber: {
            code: '381',
            country: 'rs',
            number: '668188101',
          },
          address: {
            line1: 'Baba Višnjina 45',
            line2: 'Stan 5',
            country: 'rs',
            coordinates: [
              20.474413784853965,
              44.801853000162446,
            ],
            postalCode: '11000',
            city: 'Belgrade',
            streetName: 'Baba Višnjina',
            streetNumber: 45,
            dexpressTownId: 791024,
          },
        },
        // 9 - 18 except fri/sat
        hours: new Array(7).join('.').split('.').map(
          (_, i) => ((i === 0 || i > 5) ? [-1] : [9, 17])
        ),
      },
      subscriptions: {
        ...TypesHelpers.createValues(
          'SUBSCRIPTION',
          [
            {
              id: 'TIER_FREE',
              label: 'Free / 1y',
              plan: 'Free',
              duration: '1_YEAR',
              monthly: 0,
              price: 0,
              percentage: 5,
              percentageInternational: 8,
              fixed: 50,
              warehouseFixed: 200,
              legacy: false,
            },
          ],
        ),
      },
      subscriptionsReseller: {
        ...TypesHelpers.createValues('SUBSCRIPTION', [{
          id: 'TIER_FREE',
          label: 'Free / 1y',
          plan: 'Free',
          duration: '1_YEAR',
          monthly: 0,
          price: 0,
          percentage: 5,
          percentageInternational: 8,
          fixed: 50,
        }]),
      },
    }],
    ['mk', 'MKD', 'Europe/Skopje', {
      signup: true,
      checkoutComChannel: 'srb',
      paymentMethods: ['COD_CASH', 'COD_CARD', 'FREE'],
      paymentCardProvider: '',
      paymentProviders: [],
      paymentBankAccount: '',
      supportsPayspot: false,
      email: {
        NO_REPLY_EMAIL: 'no-reply@mozhe.rs',
        REPORT_EMAIL: 'support@mozhe.rs',
      },
      warehouse: {
        details: {
          name: 'Mozhe Storage',
          email: 'hello@mozhe.rs',
          phoneNumber: {
            code: '381',
            country: 'rs',
            number: '668188101',
          },
          address: {
            line1: 'Baba Višnjina 45',
            line2: 'Stan 5',
            country: 'rs',
            coordinates: [
              20.474413784853965,
              44.801853000162446,
            ],
            postalCode: '11000',
            city: 'Belgrade',
            streetName: 'Baba Višnjina',
            streetNumber: 45,
            dexpressTownId: 791024,
          },
        },
        // 9 - 18 except fri/sat
        hours: new Array(7).join('.').split('.').map(
          (_, i) => ((i === 0 || i > 5) ? [-1] : [9, 17])
        ),
      },
      subscriptions: {
        ...TypesHelpers.createValues(
          'SUBSCRIPTION',
          [
            {
              id: 'TIER_FREE',
              label: 'Free / 1y',
              plan: 'Free',
              duration: '1_YEAR',
              monthly: 0,
              price: 0,
              percentage: 0,
              percentageInternational: 0,
              fixed: 0,
              warehouseFixed: 0,
              legacy: false,
            },
          ],
        ),
      },
      subscriptionsReseller: {
        ...TypesHelpers.createValues('SUBSCRIPTION', [{
          id: 'TIER_FREE',
          label: 'Free / 1y',
          plan: 'Free',
          duration: '1_YEAR',
          monthly: 0,
          price: 0,
          percentage: 5,
          percentageInternational: 8,
          fixed: 50,
        }]),
      },
    }],
    ['ae', 'AED', 'Asia/Dubai', {
      signup: false,
      checkoutComChannel: 'srb',
      paymentMethods: ['CARD', 'COD_CASH', 'COD_CARD', 'FREE'],
      paymentCardProvider: 'STRIPE',
      paymentProviders: ['STRIPE'],
      paymentBankAccount: '',
      supportsPayspot: false,
      email: {
        NO_REPLY_EMAIL: 'hello@thehive.rs',
        REPORT_EMAIL: 'support@ismarthome.dxbee.com',
      },
      warehouse: {
        details: {
          name: 'Dubai Storage',
          email: 'hello@thehive.rs',
          phoneNumber: {
            code: '971',
            country: 'ae',
            number: '5555555',
          },
          address: {
            line1: 'Business Bay',
            line2: 'Building 4, Second Floor, Bay Square',
            country: 'ae',
            coordinates: [
              55.279805515488235,
              25.186429760143827,
            ],
            postalCode: '00000',
            city: 'Dubai',
          },
        },
        // 9 - 18 except fri/sat
        hours: new Array(7).join('.').split('.').map(
          (_, i) => (i > 4 ? [-1] : [9, 18])
        ),
      },
      subscriptions: {
        ...TypesHelpers.createValues(
          'SUBSCRIPTION',
          [
            {
              id: 'TIER_FREE',
              label: 'Free / 1y',
              plan: 'Free',
              duration: '1_YEAR',
              monthly: 0,
              price: 0,
              percentage: 0,
              percentageInternational: 0,
              fixed: 0,
              warehouseFixed: 0,
              legacy: false,
            },
          ],
        ),
      },
      subscriptionsReseller: {
        ...TypesHelpers.createValues('SUBSCRIPTION', [{
          id: 'TIER_FREE',
          label: 'Free / 1y',
          plan: 'Free',
          duration: '1_YEAR',
          monthly: 0,
          price: 0,
          percentage: 5,
          percentageInternational: 8,
          fixed: 50,
        }]),
      },
    }],
  ].map(([id, currency, timezone, config = {}]) => ({
    id,
    country: id,
    label: TypesInternationalization.COUNTRIES_MAP[id].name,
    currency,
    timezone,
    ...config,
  }))),
  {
    getSystemCountry: systemCountry => (
      Types.SYSTEM_COUNTRY_MAP[systemCountry]
    ),
  },
);

export default Types;
